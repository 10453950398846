/* You can add global styles to this file, and also import other style files */

@import url("assets/fonts/cereal/Cereal.css");

html {
    font-synthesis: weight style small-caps;
    font-optical-sizing: auto;
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: subpixel-antialiased;
    font-smooth: always;
}

#model {
    margin: 8px;
    background-color: #343741;
}

.euiTreeView .euiTreeView {
    padding-left: 12px !important;
}

.euiTreeView--withArrows.euiTreeView--compressed .euiTreeView__iconWrapper {
    margin-left: 0 !important;
}

.euiTreeView--withArrows.euiTreeView .euiTreeView__iconWrapper {
    margin-left: 0 !important;
}

.euiListGroupItem__label {
    flex-grow: 1 !important;
}
